<template>
  <v-app>
    <v-content class="fullBg">
      <v-container class="fill-height" fluid>
        <a class="navbar-brand" href="/home.php"
          ><img
            src="/assets/images/FinSupport_logo.png"
            alt=""
            class="img-fluid"
        /></a>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>FinSupport login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid" @keyup.native.enter="login">
                  <v-text-field
                    id="email"
                    v-model="form.email"
                    label="Login"
                    name="login"
                    prepend-icon="person"
                    type="text"
                    :rules="rules.email"
                    :disabled="sending"
                    @input="serverErrors = null"
                  />
                  <v-text-field
                    id="password"
                    v-model="form.password"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    :type="showPass ? 'text' : 'password'"
                    counter
                    :rules="rules.password"
                    :disabled="sending"
                    @click:append="showPass = !showPass"
                    @input="serverErrors = null"
                  />
                </v-form>
                <a :href="pwdLostUrl">{{$t('users.password lost')}}</a>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" @click="login">Login</v-btn>
              </v-card-actions>
              <v-progress-linear
                v-if="sending"
                color="deep-purple accent-4"
                indeterminate
                rounded
                height="6"
              ></v-progress-linear>
              <v-alert v-if="errorMessage" tile class="mt-5" type="error">{{
                errorMessage
              }}</v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      rules: {
        password: [
          (v) => !!v || "Required.",
          () =>
            this.serverErrors && this.serverErrors.password
              ? this.serverErrors.password[0]
              : true,
        ],
        email: [
          (v) => !!v || "Required.",
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || "Invalid e-mail.";
          },
          () =>
            this.serverErrors && this.serverErrors.email
              ? this.serverErrors.email[0]
              : true,
        ],
      },
      showPass: false,
      errorMessage: null,
      serverErrors: null,
      sending: false,
      valid: false,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${this.header})`,
      };
    },
    pwdLostUrl() {
      return process.env.VUE_APP_FINADMIN_URL + "/login?pwdlost";
    }
  },
  methods: {
    login() {
      if ("Notification" in window && Notification.permission !== "denied") {
        Notification.requestPermission();
      }
      this.errorMessage = null;
      this.serverErrors = null;
      if (this.$refs.form.validate()) {
        this.apiLogin().catch((error) => {
          this.errorMessage = error.message;
          this.serverErrors = error.errors;
          this.$refs.form.validate();
        });
      }
    },
  },
};
</script>

<style scoped>
.fullBg {
  background-color: rgb(21, 21, 24);
  background-image: url("~@/assets/login.png");
  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}
.img-fluid {
  max-height: 100px;
  width: 320px;
  float: left;
  top: 20px;
  position: absolute;
}
</style>
